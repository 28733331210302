<template>
  <vx-card>
    <form-wizard
      color="rgba(var(--vs-primary), 1)"
      :title="'ACTIVOS EVENTUALES'"
      :subtitle="'Siga los pasos para completar el proceso'"
      :hide-buttons="false"
    >
      <tab-content title="Datos Generales" class="mb-5" :beforeChange="validateGeneralData">
        <form data-vv-scope="general-data">
          <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Información general</vs-divider>
          <div class="vx-row mb-2">
            <div class="vx-col md:w-1/2 w-full">
              <label for="name" class="w-full select-large">Nombre</label>
              <vs-input
                id="name" name="name" v-model="entryModel.name" v-validate="'required'" class="w-full"
                autocomplete="off"
              />
              <span class="text-danger">{{ errors.first("general-data.name") }}</span>
              <br/>
            </div>
            <div class="vx-col md:w-1/2 w-full">
              <label for="title" class="w-full select-large">Título</label>
              <vs-input id="title" name="title" v-model="entryModel.title" v-validate="'required'" class="w-full"
                        autocomplete="off"/>
              <span class="text-danger">{{ errors.first("general-data.title") }}</span>
              <br/>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <label for="description" class="w-full select-large">Descripción</label>
              <editor id="description" name="description" :content.sync="entryModel.description.html"
                      :resourceType="resource"/>
              <br/>
            </div>
          </div>

          <div class="vx-row mb-2">
            <div class="vx-col md:w-1/3 w-full">
              <label for="category" class="w-full select-large">Categoría</label>
              <v-select
                id="category"
                name="category"
                label="name"
                :options="categories"
                v-model="entryModel.category"
                v-validate="'required'"
                @input="getValuesFeatures(entryModel.category)"
                :placeholder="'Seleccione...'"
                :searchable="true"
                class="w-full select-large"
              />
              <span class="text-danger">{{ errors.first("general-data.category") }}</span>
              <br/>
            </div>
            <div class="vx-col md:w-1/3 w-full">
              <label for="sold" class="w-full select-large">Estado</label>
              <vs-select
                id="sold" name="sold" v-model="entryModel.sold" v-validate="'required'" class="w-full select-large"
              >
                <vs-select-item :key="false" :value="false" :text="'Disponible'" class="w-full"/>
                <vs-select-item :key="true" :value="true" :text="'Vendido'" class="w-full"/>
              </vs-select>
              <span class="text-danger">{{ errors.first("general-data.sold") }}</span>
              <br/>
            </div>
            <div class="vx-col md:w-1/3 w-full">
              <label for="price" class="w-full select-large">Precio</label>
              <vs-input id="price" name="price" v-model="entryModel.price" v-validate="'required'" class="w-full"/>
              <span class="text-danger">{{ errors.first("general-data.price") }}</span>
              <br/>
            </div>
          </div>

          <div class="vx-row mb-2">
            <div v-if="featureData && featuresFields.length > 0" v-for="(option, index) in featuresFields" :key="index"
                 class="vx-col md:w-1/3 w-full">
              <label :for="option.code" class="w-full select-large">{{ option.label }}</label>
              <vs-input :id="option.code" v-model="entryModel.features[option.code]" class="w-full"/>
              <br/>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <label for="featured" class="w-full select-large">Mostrar formulario</label>
              <vs-select
                  id="featured" name="featured"
                  v-model="entryModel.show_form" v-validate="'required'"
                  class="w-full select-large"
              >
                <vs-select-item :key="true" :value="true" :text="'Si'" class="w-full"/>
                <vs-select-item :key="false" :value="false" :text="'No'" class="w-full"/>
              </vs-select>
              <span class="text-danger">{{ errors.first("general-data.show_form") }}</span>
              <br/>
            </div>
            <div v-if="entryModel.show_form" class="vx-col md:w-1/2 w-full">
              <label for="featured" class="w-full select-large">Mostrar formulario</label>
              <vs-select
                  id="featured" name="featured"
                  v-model="entryModel.form_placement" v-validate="'required'"
                  class="w-full select-large"
              >
                <vs-select-item :key="'on_banner_bar'" :value="'on_banner_bar'" :text="'En banner'" class="w-full"/>
                <vs-select-item :key="'before_content'" :value="'before_content'" :text="'Antes del contenido'" class="w-full"/>
                <vs-select-item :key="'after_content'" :value="'after_content'" :text="'Después del contenido'" class="w-full"/>
              </vs-select>
              <span class="text-danger">{{ errors.first("general-data.form_placement") }}</span>
              <br/>
            </div>
          </div>

          <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Ubicación</vs-divider>
          <div class="vx-row mb-2">
            <div class="vx-col md:w-1/2 w-full">
              <label for="state" class="w-full select-large">Departamento</label>
              <v-select
                id="state"
                name="state"
                label="name"
                index="id"
                :options="states"
                :placeholder="'Seleccione..'"
                :searchable="true"
                v-model="entryModel.state"
                v-validate="'required'"
                @input="getCities(entryModel.state)"
                class="w-full"
              />
              <span class="text-danger">{{ errors.first("general-data.state") }}</span>
              <br/>
            </div>

            <div class="vx-col md:w-1/2 w-full">
              <label for="city" class="w-full select-large">Ciudad</label>
              <v-select
                id="city"
                name="city"
                label="name"
                index="id"
                :options="cities"
                :placeholder="'Seleccione..'"
                :searchable="true"
                v-model="entryModel.city"
                v-validate="'required'"
                class="w-full"
              />
              <span class="text-danger">{{ errors.first("general-data.city") }}</span>
              <br/>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <label for="address" class="w-full select-large">Dirección</label>
              <vs-textarea id="address" name="address" v-model="entryModel.address" v-validate="'required'" rows="3"
                           class="w-full"/>
              <span class="text-danger">{{ errors.first("general-data.address") }}</span>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <label for="latitude" class="w-full select-large">Latitud</label>
              <vs-input
                id="latitude" name="latitude" v-model="entryModel.geolocation.lat" v-validate="'required'"
                class="w-full"
              />
              <span class="text-danger">{{ errors.first("general-data.latitude") }}</span>
              <br/>
            </div>

            <div class="vx-col md:w-1/2 w-full">
              <label for="longitude" class="w-full select-large">Longitud</label>
              <vs-input
                id="longitude" name="longitude" v-model="entryModel.geolocation.lng" v-validate="'required'"
                class="w-full"
              />
              <span class="text-danger">{{ errors.first("general-data.longitude") }}</span>
              <br/>
            </div>
          </div>

          <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Datos de publicación</vs-divider>
          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full">
              <label for="featured" class="w-full select-large">Destacado</label>
              <vs-select
                id="featured" name="featured" v-model="entryModel.featured" v-validate="'required'"
                class="w-full select-large"
              >
                <vs-select-item :key="true" :value="true" :text="'Si'" class="w-full"/>
                <vs-select-item :key="false" :value="false" :text="'No'" class="w-full"/>
              </vs-select>
              <span class="text-danger">{{ errors.first("general-data.featured") }}</span>
              <br/>
            </div>

            <div class="vx-col md:w-1/3 w-full">
              <label for="priority" class="w-full select-large">Prioridad</label>
              <vs-input
                type="number"
                id="priority"
                name="priority"
                min="1"
                max="10"
                v-model="entryModel.priority"
                v-validate="'required'"
                class="w-full"
              />
              <span class="text-danger">{{ errors.first("general-data.priority") }}</span>
              <br/>
            </div>
            <div class="vx-col md:w-1/3 w-full">
              <label for="approved" class="w-full select-large">Aprobado</label>
              <vs-select
                id="approved" name="approved" v-model="entryModel.approved" v-validate="'required'"
                class="w-full select-large"
              >
                <vs-select-item :key="true" :value="true" :text="'Si'" class="w-full"/>
                <vs-select-item :key="false" :value="false" :text="'No'" class="w-full"/>
              </vs-select>
              <span class="text-danger">{{ errors.first("general-data.approved") }}</span>
              <br/>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <label for="publish_at" class="w-full select-large">Fecha y hora de publicación</label>
              <flat-pickr
                id="publish_at" name="publish_at" v-model="entryModel.publish_at" :config="configDateTimePicker"
                class="w-full flatpickr flatpickr-input"
              />
              <br/>
            </div>
            <div class="vx-col md:w-1/2 w-full">
              <label for="expire_at" class="w-full select-large">Fecha y hora de expiración</label>
              <flat-pickr
                id="expire_at" name="expire_at" v-model="entryModel.expire_at" :config="configDateTimePicker"
                class="w-full flatpickr flatpickr-input"
              />
              <br/>
            </div>
          </div>
        </form>

      </tab-content>

      <!-- tab 2 content -->
      <tab-content title="Medios" class="mb-5">
        <vs-divider class="vx-col w-full mt-5" position="right" color="light">Open graph</vs-divider>
        <div class="vx-row">
          <div class="vx-col w-full mb-5">
            <uploader
              :classFile="'file-upload-1'"
              @files-removed="removedFiles('open_graph')"
              @files-updated="openGraphUpload"
              :maxNumberOfFiles="1"
              :downloadedFiles="entryModel.media.open_graph"
            />
          </div>
        </div>

        <vs-divider class="vx-col w-full mt-5" position="right" color="light">Thumbnail</vs-divider>
        <div class="vx-row">
          <div class="vx-col w-full mb-5">
            <uploader
              :classFile="'file-upload-2'"
              :maxNumberOfFiles="1"
              @files-removed="removedFiles('thumbnail')"
              @files-updated="thumbnailUpload"
              :downloadedFiles="entryModel.media.thumbnail"
            />
          </div>
        </div>

        <vs-divider class="vx-col w-full mt-5" position="right" color="light">Carrusel</vs-divider>
        <div class="vx-row">
          <div class="vx-col w-full mb-5">
            <uploader
              :classFile="'file-upload-3'"
              @files-removed="removeCarouselFiles($event)"
              @files-updated="setImages"
              :downloadedFiles="entryModel.media.carousel"
            />
          </div>
        </div>
      </tab-content>

      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <vs-button
            v-if="props.activeTabIndex > 0"
            @click.native="props.prevTab()"
            class="wizard-footer-left finish-button"
          >
            Anterior
          </vs-button>
        </div>

        <div class="wizard-footer-right">
          <!-- General data next button -->
          <vs-button
            v-if="props.activeTabIndex === 0"
            class="wizard-footer-right finish-button"
            @click.native="props.nextTab()"
            :disabled="!requiredGeneralDataFieldsFilled"
          >
            Siguiente
          </vs-button>

          <!-- Any other tab -->
          <vs-button
            v-if="props.activeTabIndex !== 0 && !props.isLastStep"
            class="wizard-footer-right finish-button"
            @click.native="props.nextTab()"
          >
            Siguiente
          </vs-button>

          <!-- Last tab -->
          <vs-button
            v-if="props.isLastStep"
            class="wizard-footer-right finish-button"
            @click="createOrUpdateEntry"
          >
            Guardar
          </vs-button>

          <!-- Shown in every tab -->
          <vs-button
            color="danger"
            type="border"
            class="wizard-footer-right finish-button mr-3"
            @click="goToEntriesList"
          >
            Cancelar
          </vs-button>
        </div>
      </template>
    </form-wizard>
  </vx-card>
</template>

<script>
  import AttributeOptionsClient from "../../utils/attributeOptionsClient";
  import CoreClient from "../../utils/coreClient";
  import EntriesClient from "../../utils/entriesClient";
  import Notifier from "./../../utils/notification";
  import Configurations from "./../../utils/configurations";
  import Upload from "./../../utils/upload";
  import {FormWizard, TabContent} from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import vSelect from "vue-select";
  import Editor from "./../../components/Editor.vue";
  import Uploader from "./../../components/Uploader.vue";
  import {Validator} from 'vee-validate';

  let notifier = new Notifier();
  let configurations = new Configurations();
  const ENTRY_CLIENT = new EntriesClient("assets");
  const ATTRIBUTE_OPTIONS_CLIENT = new AttributeOptionsClient("assets");
  const STATES_CLIENT = new CoreClient("states");
  const CITIES_CLIENT = new EntriesClient("cities");
  const UPLOAD = new Upload("resource");

  let dict = [
    "name", "title", "category", "sold", "price", "state", "city", "address", "featured", "priority", "approved",
    "longitude", "latitude"
  ].reduce(
    (previousValue, currentValue) => {
      previousValue[currentValue] = {required: "* Este campo es requerido"};
      return previousValue
    },
    {}
  );
  Validator.localize("es", {custom: dict});

  export default {
    data() {
      return {
        configDateTimePicker: configurations.configDatePicker(),
        resource: "assets",
        featureData: false,
        files: [],
        carouselCopy: [],
        carouselUpload: [],
        cities: [],
        states: [],
        categories: [],
        featuresFields: [],
        entryModel: {
          name: null,
          title: null,
          category: null,
          price: "0.00",
          address: null,
          geolocation: {
            lat: null,
            lng: null,
          },
          featured: false,
          priority: 10,
          publish_at: null,
          expire_at: null,
          sold: false,
          city: null,
          state: null,
          approved: true,
          features: {},
          description: {
            html: null,
          },
          form: null,
          media: {
            open_graph: {
              url: null,
              meta: {
                title: null,
                description: null
              }
            },
            thumbnail: {
              url: null,
              meta: {
                title: null,
                description: null
              }
            },
            carousel: []
          },
          show_form: true,
          form_placement: 'on_banner_bar',
        }
      };
    },

    mounted() {
      if (this.$route.params.id !== undefined) {
        this.$vs.loading();
        this.loadEntryData(this.$route.params.id)
          .then(() => {
            this.$vs.loading.close();
          });
      } else {
        this.$vs.loading();
        this.getStates(false)
          .then(() => {
            this.getCategories(false)
              .then(() => {
                this.$vs.loading.close();
              });
          });
      }
    },

    computed: {
      requiredGeneralDataFieldsFilled() {
        return !this.errors.any("general-data")
          && this.entryModel.name !== null && this.entryModel.name.trim() !== ""
          && this.entryModel.title !== null && this.entryModel.title.trim() !== ""
          && this.entryModel.description.html !== null && this.entryModel.description.html.trim() !== ""
          && this.entryModel.category !== null
          && this.entryModel.price !== null
          && this.entryModel.state !== null
          && this.entryModel.city !== null
          && this.entryModel.address !== null
          && this.entryModel.geolocation.lat !== null
          && this.entryModel.geolocation.lng !== null
          && this.entryModel.priority !== null;
      }
    },

    methods: {
      async getStates(showLoading = true) {
        if (showLoading) this.$vs.loading();

        await STATES_CLIENT.all()
          .then(response => {
            if (showLoading) this.$vs.loading.close();
            this.states = response.data;
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async getCities(state, showLoading = true) {
        if (state !== null && state !== undefined && state !== "") {
          if (showLoading) this.$vs.loading();

          await CITIES_CLIENT.all({queryStrings: {state_id: state.id}})
            .then(response => {
              this.cities = response.data;
              if (showLoading) this.$vs.loading.close();
            })
            .catch(error => {
              if (showLoading) this.$vs.loading.close();
              notifier.notification("error");
            });
        } else {
          this.cities = [];
          this.entryModel.city = null;
        }
      },

      async getCategories(showLoading = true) {
        if (showLoading) this.$vs.loading();

        await ATTRIBUTE_OPTIONS_CLIENT.categories({queryStrings: {representation_type: "full"}})
          .then(response => {
            if (showLoading) this.$vs.loading.close();
            this.categories = response.data;
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async getValuesFeatures(value, call) {
        call ? await this.getCategories() : "";
        this.featuresFields = [];

        if (value !== null) {
          if (value.features !== undefined) {
            value.features.forEach(item => {
              this.featuresFields.push(item);
            });
          } else {
            this.categories.forEach(item => {
              if (item.name === value.name) {
                item.features.forEach(feature => this.featuresFields.push(feature))
              }
            })
          }
        }
        this.featureData = true;
      },

      async loadEntryData(entryId) {
        await ENTRY_CLIENT.retrieve({pathParameters: {entryId: entryId}})
          .then(response => {
            this.getStates(false)
              .then(() => {
                if (response.data.city !== undefined && response.data.city.id !== null) {
                  this.getCities(response.data.state, false)
                    .then(() => {
                      this.getCategories(false)
                        .then(() => {
                          Object.assign(this.entryModel, response.data);
                          this.getValuesFeatures(this.entryModel.category);
                        });
                    });
                }
              });
          })
          .catch(error => {
            notifier.notification("error");
          });
      },

      async createOrUpdateEntry() {
        this.$vs.loading();
        await this.uploadImages();
        this.entryModel.media.carousel = this.carouselUpload;

        this.cleanModel();

        if (this.entryModel.id !== null && this.entryModel.id !== undefined && this.entryModel.id !== "") {
          await ENTRY_CLIENT.update({pathParameters: {entryId: this.entryModel.id}, data: this.entryModel})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("updated");
              this.goToEntriesList();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });

        } else {
          await ENTRY_CLIENT.create({data: this.entryModel})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("created");
              this.goToEntriesList();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        }
      },

      setImages(files) {
        this.files = files;
      },

      async openGraphUpload(files) {
        if (files.length > 0 && files[0].source === "Dashboard") {
          this.$vs.loading();
          await UPLOAD.uploadFiles({
            queryStrings: {resource_type: this.resource, content_type: files[0].type},
            files: files
          })
            .then(response => {
              this.entryModel.media.open_graph.url = response;
              this.entryModel.media.open_graph.meta.title = files[0].meta.name;
              this.entryModel.media.open_graph.meta.description = files[0].meta.caption;
            })
            .catch(error => {
              notifier.notification("error");
            });
          this.$vs.loading.close();
        }
      },

      async thumbnailUpload(files) {
        if (files.length > 0 && files[0].source === "Dashboard") {
          this.$vs.loading();
          await UPLOAD.uploadFiles({
            queryStrings: {resource_type: this.resource, content_type: files[0].type},
            files: files
          })
            .then(response => {
              this.entryModel.media.thumbnail.url = response;
              this.entryModel.media.thumbnail.meta.title = files[0].meta.name;
              this.entryModel.media.thumbnail.meta.description = files[0].meta.caption;
            })
            .catch(error => {
              notifier.notification("error");
            });
          this.$vs.loading.close();
        }
      },

      async uploadImages() {
        if (this.files) {
          await Promise.all(
            this.files.map((curr, index) => this.uploadFile(curr, index))
          );
        }
      },

      async uploadFile(files, index) {
        let carousel = this.entryModel.media.carousel;

        if (this.carouselCopy.length > 0) {
          carousel = [];
        }

        if (files !== undefined && files !== null && files !== "") {
          this.$vs.loading();
          await UPLOAD.uploadFiles({
            queryStrings: {resource_type: this.resource, content_type: files.type},
            files: files,
            multiple: true
          })
            .then(response => {
              let slide = {
                url: response,
                meta: {
                  title: files.meta.name,
                  description: files.meta.caption
                }
              };
              this.carouselUpload.push(slide);
            })
            .catch(error => {
              notifier.notification("error");
            });
          this.$vs.loading.close();
        }
      },

      removeCarouselFiles(data) {
        this.files.forEach((item, index, object) => {
          if (item.id === data.id) {
            object.splice(index, 1);
          }
        });
      },

      async removedFiles(files) {
        let mediaType = this.entryModel.media;
        let body = mediaType[files].url;

        body = JSON.stringify({url: body});

        await UPLOAD.removeFiles({data: body}).then(response => {
          mediaType[files] = {
            url: null,
            meta: {
              title: null,
              description: null
            }
          }
        }).catch(error => {
          notifier.notification("error");
        });
      },

      goToEntriesList() {
        this.$router.push({name: "assets"});
      },

      cleanModel() {
        Object.keys(this.entryModel).forEach(key => {
          if (this.entryModel[key] === null || this.entryModel[key] === "") {
            delete this.entryModel[key];
          }
        })
      },

      validateGeneralData() {
        return new Promise((resolve, reject) => {
          this.$validator.validateAll("general-data").then(result => {
            if (result) {
              resolve(true);
            } else {
              reject("Please fix invalid fields");
            }
          })
        });
      }
    },

    components: {
      Editor,
      FormWizard,
      TabContent,
      flatPickr,
      Uploader,
      "v-select": vSelect
    }
  };
</script>